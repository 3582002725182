* {
  outline: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: border-box;
}

svg {
  /** Fix large viewBox and translates in IE */
  overflow: hidden;
}

:root{
  --main-color: var(--main-color);
}

#nprogress .bar {
  background: var(--main-color);
}

#nprogress .peg {
  box-shadow: 0 0 10px var(--main-color), 0 0 5px var(--main-color);
}

#nprogress .spinner-icon {
  border-top-color: var(--main-color);
  border-left-color: var(--main-color);
}

#__next {
  display: flex;
}

html,
body {
  width: 100%;
  min-width: 1260px;
}

input,
textarea {
  -webkit-appearance: none;
  border-radius: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-ms-clear {
  display: none !important;
}

input::-ms-reveal {
  display: none !important;
}

button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

main {
  display: block;
}

ul,
ol,
nav {
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s;
  cursor: pointer;
}

a:hover,
a:focus {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
}

body {
  font-family: 'Montserrat',
  Arial,
  sans-serif;
  font-size: 16px;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1260px;
}
